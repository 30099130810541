<template>
  <div
    class="bg-white px-8 quotation-detail-page"
    style="height: calc(100vh - 50px)"
  >
    <v-row v-if="pageLoaded">
      <v-col md="12" class="py-0">
        <v-row>
          <v-col md="6" class="d-flex align-center justify-start">
            <v-chip
              label
              outlined
              color="blue darken-4"
              class="font-weight-600"
              >{{ dbQuotation.barcode }}</v-chip
            >
            <v-chip
              label
              :color="dbQuotation?.status_relation?.color"
              :text-color="dbQuotation?.status_relation?.textcolor"
              class="ml-2 text-uppercase font-weight-600"
              >{{ dbQuotation?.status_relation?.text }}</v-chip
            >
            <v-chip
              v-if="dbQuotation?.is_contract == 1 && dbQuotation?.status == 2"
              label
              color="green darken-4"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Contract</v-chip
            >
            <v-chip
              v-if="dbQuotation?.invoice_converted == 1"
              label
              color="green"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Invoice</v-chip
            >
            <v-chip
              v-if="false && dbQuotation?.visit_converted == 1"
              label
              color="orange"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Converted to Visit</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 1"
              label
              color="light-green darken-3"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >New</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 2"
              label
              color="orange darken-4 white--text"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >Maintenance</v-chip
            >
            <v-chip
              v-if="dbQuotation?.type == 2"
              label
              :color="maintenanceType.color"
              text-color="white"
              class="ml-2 text-uppercase font-weight-600"
              >{{ maintenanceType.text }}</v-chip
            >
            <template v-if="dbQuotation?.has_tool == 1">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="green darken-4"
                    class="ml-2"
                    >mdi-tools</v-icon
                  >
                </template>
                <span>Tools</span>
              </v-tooltip>
            </template>
          </v-col>
          <v-col md="6" class="d-flex align-center justify-end">
            <v-btn
              v-if="dbQuotation?.status == 1"
              v-on:click="updateMoreAction('edit')"
              depressed
              color="cyan white--text"
              class="custom-bold-button"
              ><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
            >
            <template
              v-if="
                dbQuotation?.more_actions && dbQuotation?.more_actions.length
              "
            >
              <template v-if="dbQuotation?.more_actions.length > 3">
                <v-menu
                  v-if="
                    dbQuotation?.more_actions &&
                    dbQuotation?.more_actions.length
                  "
                  transition="slide-y-transition"
                  bottom
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      color="cyan white--text"
                      class="custom-bold-button mx-2"
                      >More...
                      <v-icon small right>mdi-chevron-down</v-icon></v-btn
                    >
                  </template>
                  <v-list>
                    <template
                      v-for="(more, index) in dbQuotation?.more_actions"
                    >
                      <v-list-item
                        link
                        v-on:click="updateMoreAction(more.action)"
                        :key="index"
                        :disabled="more.disabled"
                      >
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue">{{
                            more.icon
                          }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title
                          class="font-weight-500 font-size-14"
                          >{{ more.title }}</v-list-item-title
                        >
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
              </template>
              <template v-else>
                <v-btn
                  v-for="(more, index) in dbQuotation?.more_actions"
                  v-on:click="updateMoreAction(more.action)"
                  :key="index"
                  :disabled="more.disabled"
                  depressed
                  :color="more.color"
                  class="custom-bold-button mx-2 text-white"
                  ><v-icon small left>{{ more.icon }}</v-icon>
                  {{ more.title }}</v-btn
                >
              </template>
            </template>
            <v-btn v-on:click="goBack()" depressed class="custom-bold-button"
              ><v-icon small left>mdi-chevron-left</v-icon>Back</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col md="5">
        <v-row>
          <v-col md="12">
            <span
              class="cursor-pointer color-custom-blue font-weight-700 font-size-19"
            >
              <router-link
                :to="
                  getDefaultRoute('customer.detail', {
                    params: {
                      id: dbQuotation?.customer_relation?.id,
                    },
                  })
                "
                >{{ dbQuotation?.customer_relation?.display_name }}
              </router-link>
            </span>
          </v-col>
          <v-col md="6">
            <label class="my-0">Billing Location</label>
            <template v-if="dbQuotation?.billing_relation?.id">
              <p class="mb-0 text-capitalize">
                <template v-if="dbQuotation?.billing_relation?.property_name">
                  {{ dbQuotation?.billing_relation?.property_name }}<br />
                </template>

                <template v-if="dbQuotation?.billing_relation.unit_no"
                  >{{ dbQuotation?.billing_relation.unit_no }},</template
                >
                <template v-if="dbQuotation?.billing_relation.street_1"
                  >{{ dbQuotation?.billing_relation.street_1 }},</template
                >
                {{ dbQuotation?.billing_relation.street_2 }}
                <br />
                {{ dbQuotation?.billing_relation.country }}
                <template
                  v-if="
                    dbQuotation?.billing_relation.zip_code &&
                    dbQuotation?.billing_relation.zip_code != '000000'
                  "
                >
                  {{ dbQuotation?.billing_relation.zip_code }}
                </template>
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbQuotation?.billing_contact_person_relation?.id">
              <p class="mb-0">
                {{ dbQuotation?.billing_contact_person_relation?.display_name }}
              </p>
              <p class="mb-0">
                {{
                  dbQuotation?.billing_contact_person_relation?.primary_phone
                }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{
                  dbQuotation?.billing_contact_person_relation?.primary_email
                }}
              </p>
            </template>
          </v-col>
          <v-col md="6">
            <label class="my-0 text-capitalize">Site Info</label>
            <template v-if="dbQuotation?.property_relation?.id">
              <p class="mb-0">
                <template v-if="dbQuotation?.property_relation?.property_name">
                  {{ dbQuotation?.property_relation?.property_name }}<br />
                </template>
                <template v-if="dbQuotation?.property_relation?.unit_no"
                  >{{ dbQuotation?.property_relation?.unit_no }},</template
                >
                <template v-if="dbQuotation?.property_relation?.street_1"
                  >{{ dbQuotation?.property_relation?.street_1 }},</template
                >
                {{ dbQuotation?.property_relation?.street_2 }}
                <br />
                {{ dbQuotation?.property_relation?.country }}
                <template
                  v-if="
                    dbQuotation?.property_relation?.zip_code &&
                    dbQuotation?.property_relation?.zip_code != '000000'
                  "
                >
                  {{ dbQuotation?.property_relation?.zip_code }}
                </template>
              </p>
            </template>
            <label class="mt-3">Contact Details</label>
            <template v-if="dbQuotation?.property_contact_person_relation?.id">
              <p class="mb-0">
                {{
                  dbQuotation?.property_contact_person_relation?.display_name
                }}
              </p>
              <p class="mb-0">
                {{
                  dbQuotation?.property_contact_person_relation?.primary_phone
                }}
              </p>
              <p class="mb-0 d-flex align-center">
                {{
                  dbQuotation?.property_contact_person_relation?.primary_email
                }}
              </p>
            </template>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="7" class="gray-background">
        <table width="100%">
          <tr>
            <td
              colspan="2"
              class="color-custom-blue font-weight-700 font-size-19"
            >
              {{ dbQuotation?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Rate opportunity</td>
            <td class="py-0 my-0">
              <v-rating
                readonly
                class="my-0 py-0"
                v-model.trim="dbQuotation.rating"
                background-color="orange lighten-3"
                color="orange"
              ></v-rating>
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Date</td>
            <td class="font-weight-600">{{ formatDate(dbQuotation?.date) }}</td>
          </tr>
          <tr v-if="dbQuotation?.contract_relation?.id">
            <td class="font-weight-600" width="20%">Contract #</td>
            <td
              class="font-weight-600 cursor-pointer"
              style="color: #24326d !important"
              v-on:click="routeToContract()"
            >
              {{ dbQuotation?.contract_relation?.barcode }} -
              {{ dbQuotation?.contract_relation?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Sale by</td>
            <td class="font-weight-600">
              {{ dbQuotation?.sale_by_relation?.display_name }}
            </td>
          </tr>
          <tr v-if="dbQuotation?.enquiry_relation">
            <td class="font-weight-600" width="20%">Enquiry</td>
            <td
              class="font-weight-600 cursor-pointer color-custom-blue"
              v-on:click="routeToEnquiry()"
            >
              {{ dbQuotation?.enquiry_relation?.barcode }} -
              {{ dbQuotation?.enquiry_relation?.title }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Customer ref</td>
            <td class="font-weight-600">
              <template v-if="dbQuotation?.customer_ref">
                {{ dbQuotation?.customer_ref }}
              </template>
              <em v-else class="text-muted">no customer ref</em>
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Your ref</td>

            <td class="font-weight-600">
              <template v-if="dbQuotation?.your_ref">
                {{ dbQuotation?.your_ref }}
              </template>
              <em v-else class="text-muted">no Your ref</em>
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Quote by</td>
            <td class="font-weight-600">
              {{ dbQuotation?.quote_by_relation?.display_name }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-600" width="20%">Quote Amount</td>
            <td class="font-weight-600">
              {{ formatMoney(dbQuotation?.total) }}
            </td>
          </tr>
          <template v-if="dbQuotation?.type == 2">
            <tr v-if="dbQuotation?.contract_start && dbQuotation?.contract_end">
              <td class="font-weight-600" width="20%">Contract Duration</td>
              <td class="font-weight-600">
                This Contract will start on
                {{ formatDate(dbQuotation.contract_start) }} and finish on
                {{ formatDate(dbQuotation.contract_end) }}
              </td>
            </tr>
          </template>
          <tr v-if="false">
            <td class="font-weight-600" width="20%">No. of Contract Days</td>
            <td class="font-weight-600">
              {{ contractSchedule.length }}
            </td>
          </tr>
        </table>
      </v-col>
      <v-col md="12">
        <v-tabs
          active-class="custom-tab-active"
          v-model="quotationTab"
          background-color="transparent"
          color="cyan"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-14 font-weight-600 px-8" href="#overview">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/quotation.svg')" />
              <!--end::Svg Icon-->
            </span>
            Overview
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#line-item">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/line-item.svg')" />
              <!--end::Svg Icon-->
            </span>
            Line Items
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#invoice">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/invoice.svg')" />
              <!--end::Svg Icon-->
            </span>
            Invoices
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#visit">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/visit.svg')" />
              <!--end::Svg Icon-->
            </span>
            Visits
          </v-tab>
          <v-tab class="font-size-14 font-weight-600 px-8" href="#history">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg :src="$assetURL('media/custom-svg/history.svg')" />
              <!--end::Svg Icon-->
            </span>
            History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="quotationTab" class="custom-border-top">
          <v-tab-item value="overview">
            <v-expansion-panels mandatory accordion>
              <v-expansion-panel v-if="dbQuotation?.is_contract == 1">
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      CONTRACT
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <table width="80%">
                      <tbody>
                        <tr>
                          <td class="font-weight-600" width="10%">
                            Start Date
                          </td>
                          <td class="font-weight-600" width="40%">
                            {{ formatDate(dbQuotation?.contract_start_date) }}
                          </td>
                          <td class="font-weight-600" width="10%">End Date</td>
                          <td class="font-weight-600" width="40%">
                            {{ formatDate(dbQuotation?.contract_end_date) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-weight-600">Schedule</td>
                          <td class="font-weight-600" colspan="3">
                            {{ dbQuotation?.contract_message }}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <v-btn
                      v-if="
                        dbQuotation?.is_contract &&
                        maintenanceType?.value != 'onetime'
                      "
                      depressed
                      small
                      v-on:click="scheduleDialog = true"
                      color="orange darken-4 white--text"
                      class="mt-4 ml-2 custom-bold-button"
                      >Show Schedule</v-btn
                    >
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      DESCRIPTION
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      v-if="dbQuotation?.description"
                      v-html="dbQuotation?.description"
                    />
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      no description
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      TERMS &amp; CONDITIONS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <div
                      v-if="dbQuotation?.term_conditions"
                      v-html="dbQuotation?.term_conditions"
                    />
                    <div
                      v-else
                      class="text--secondary"
                      style="font-style: italic"
                    >
                      no terms &amp; conditions
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="dbQuotation.status != 1">
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <template v-if="dbQuotation.status == 2">
                      <h3
                        class="font-weight-600 custom-headline color-custom-blue font-size-14"
                      >
                        ACCEPTED REMARK
                      </h3>
                    </template>
                    <template v-if="dbQuotation.status == 3">
                      <h3
                        class="font-weight-600 custom-headline color-custom-blue font-size-14"
                      >
                        REJECTED REMARK
                      </h3>
                    </template>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <div class="w-100">
                    <template v-if="dbQuotation.status == 2">
                      <div
                        v-if="dbQuotation?.accepted_remarks"
                        v-html="dbQuotation?.accepted_remarks"
                      />
                      <div
                        v-else
                        class="text--secondary"
                        style="font-style: italic"
                      >
                        no remark
                      </div>
                    </template>

                    <template v-if="dbQuotation.status == 3">
                      <div
                        v-if="dbQuotation?.approved_reject_remarks"
                        v-html="dbQuotation?.approved_reject_remarks"
                      />
                      <div
                        v-else
                        class="text--secondary"
                        style="font-style: italic"
                      >
                        no remark
                      </div>
                    </template>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="grey lighten-4">
                  <v-card-title class="headline">
                    <h3
                      class="font-weight-600 custom-headline color-custom-blue font-size-14"
                    >
                      INTERNAL NOTES &amp; ATTACHMENTS
                    </h3>
                  </v-card-title>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-4 custom-grey-border">
                  <v-row>
                    <v-col md="6" class="custom-border-right">
                      <label class="font-size-16 font-weight-600"
                        >Internal Note</label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.admin_note"
                          v-html="dbQuotation?.admin_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          no internal note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="6" class="custom-border-left">
                      <label class="font-size-16 font-weight-600"
                        >Client Note
                        <span class="text--secondary font-weight-500"
                          >(visible on PDF)</span
                        ></label
                      >
                      <div class="w-100 pl-1 pt-2 custom-border-top-1px">
                        <div
                          v-if="dbQuotation?.client_note"
                          v-html="dbQuotation?.client_note"
                        />
                        <div
                          v-else
                          class="text--secondary"
                          style="font-style: italic"
                        >
                          no client note
                        </div>
                      </div>
                    </v-col>
                    <v-col md="12" class="custom-border-top mt-4">
                      <v-row>
                        <v-col md="6">
                          <label class="font-size-16 font-weight-600"
                            >Attachments ({{
                              dbQuotation.attachments.length
                            }})</label
                          >
                          <table width="100%" class="custom-border-top-1px">
                            <tbody>
                              <tr
                                v-for="(row, index) in dbQuotation.attachments"
                                :key="`view-attach-${index}`"
                              >
                                <td
                                  valign="middle"
                                  width="80%"
                                  class="pr-2 py-2"
                                >
                                  {{ row.name }}
                                </td>
                                <td
                                  valign="middle"
                                  width="20%"
                                  align="right"
                                  class="pl-2 py-2"
                                >
                                  <v-icon color="cyan" class="mr-2"
                                    >mdi-download</v-icon
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-tab-item>
          <v-tab-item value="line-item">
            <line-item-view :quote-type="dbQuotation?.type ?? 1" />
          </v-tab-item>
          <v-tab-item value="invoice">
            <internal-invoice type="quotation" :type-id="dbQuotation?.id" />
          </v-tab-item>
          <v-tab-item value="visit">
            <internal-visit
              :customer-id="dbQuotation?.customer"
              :property-id="dbQuotation?.property"
              :quotation-id="dbQuotation?.id"
              detailType="tickets"
              visitType="all"
              internal
              :visit-status="0"
              detailRoute="visit.detail"
            />
          </v-tab-item>
          <v-tab-item value="history">
            <internal-history-detail
              type="quotation"
              :type_id="dbQuotation?.id"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="12">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <p class="text-center font-weight-600 font-size-19">Please Wait...</p>
        <v-progress-linear rounded indeterminate height="8" color="orange" />
      </v-col>
    </v-row>
    <confirm-dialog
      key="confirm-dialog-quotation"
      :common-dialog="confirmDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title> Confirmation </template>
      <template v-slot:body>
        <v-container class="px-10" fluid>
          <p class="font-weight-600 font-size-19">
            Are you sure, you want to
            <span class="font-weight-700">{{ getConfirmTitle() }}</span
            >?
          </p>
          <v-layout
            class="my-4"
            v-if="
              [
                'mark_as_approved',
                'mark_as_rejected',
                'mark_as_accepted',
              ].includes(confirmType)
            "
          >
            <v-flex>
              <label class="font-weight-500 font-size-16 required"
                >Remark</label
              >
              <TextAreaField
                auto-grow
                dense
                filled
                color="cyan"
                v-model.trim="actionData.remark"
                placeholder="Remark"
                solo
                flat
                row-height="25"
                counter="250"
              />
            </v-flex>
          </v-layout>
          <template v-if="confirmType == 'mark_as_accepted'">
            <v-layout class="mt-4">
              <v-flex md12>
                <label class="font-weight-500 font-size-16"
                  >Attachments (Max 5)</label
                >
                <table width="100%">
                  <tbody>
                    <tr
                      v-for="(row, index) in actionData.attachments"
                      :key="index"
                    >
                      <td width="50%" class="pl-0 pr-2 py-2">
                        <v-file-input
                          prepend-inner-icon="mdi-attachment mdi-rotate-90"
                          placeholder="Click here to select file"
                          label="Click here to select file"
                          prepend-icon=""
                          color="cyan"
                          solo
                          flat
                          v-model="row.accepted_file"
                          dense
                          v-on:change="updateFileName(index)"
                          hide-details
                        ></v-file-input>
                      </td>
                      <td width="50%" class="pr-0 pl-2 py-2">
                        <v-text-field
                          placeholder="File Name"
                          label="File Name"
                          dense
                          solo
                          flat
                          color="cyan"
                          v-model="row.file_name"
                          hide-details
                          class="has-delete-outer-icon"
                          append-outer-icon="mdi-delete"
                          v-on:click:append-outer="removeRow(index)"
                          :suffix="getFileExtension(row.file_type)"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr v-if="actionData.attachments.length < 5">
                      <td colspan="2" class="pl-0">
                        <v-btn
                          color="cyan white--text"
                          small
                          v-on:click="addAttachmentRow()"
                          class="custom-bold-button"
                          >Add More...</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-flex>
            </v-layout>
            <v-layout v-if="dbQuotation?.type == 2" class="my-4">
              <v-flex md12>
                <p class="my-0 red--text font-weight-600">
                  Note: Once you save and accept, a Contract will be generated
                  with a draft status.
                </p>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="confirmLoading"
          v-on:click="confirmDialog = false"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          No! Close
        </v-btn>
        <v-btn
          v-on:click="confirmSubmit()"
          :disabled="confirmLoading"
          :loading="confirmLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
        >
          Yes! Continue
        </v-btn>
      </template>
    </confirm-dialog>
    <confirm-dialog
      key="show-schedule-dialog-quotation"
      :common-dialog="scheduleDialog"
      :dialog-width="dialogWidth"
    >
      <template v-slot:title>
        Contract Schedule ({{ contractSchedule.length }})</template
      >
      <template v-slot:body>
        <v-container fluid>
          <table width="100%">
            <tr>
              <th class="text-center">S.No.</th>
              <th>Date</th>
              <th>Day</th>
              <th>Status</th>
            </tr>
            <tr
              v-for="(row, index) in contractSchedule"
              :key="index"
              :class="{ 'visit-row-hover': row.is_edited }"
            >
              <td
                class="font-size-14 font-weight-500"
                align="center"
                valign="middle"
              >
                {{ index + 1 }}.
              </td>
              <td class="font-size-14 font-weight-500">
                {{ formatDate(row.start_date) }}
              </td>
              <td class="font-size-14 font-weight-500">
                {{ row.days_of_week }}
              </td>
              <td>
                <v-icon color="green lighten-1" v-if="row.status == 1"
                  >mdi-check</v-icon
                >
                <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="scheduleDialog = false"
          depressed
          class="custom-grey-border custom-bold-button"
        >
          Close
        </v-btn>
      </template>
    </confirm-dialog>
    <send-mail
      :mail-dialog="mailDialog"
      title="Send as Email"
      type="quotation"
      v-on:close="mailDialog = false"
      :type-id="quotationId"
      v-on:success="getQuotation()"
    ></send-mail>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import { SET_ERROR } from "@/core/services/store/common.module";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import JwtService from "@/core/services/jwt.service";
import { UPLOAD, PATCH, GET } from "@/core/services/store/request.module";
import {
  SET_VIEW_LINE_ITEM,
  SET_VIEW_CALCULATION,
} from "@/core/services/store/line.item.module";
import LineItemViewV2 from "@/view/pages/partials/Line-Item-View-V2.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog";
import ObjectPath from "object-path";
import SendMail from "@/view/pages/partials/Send-Mail";
import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import InternalVisit from "@/view/pages/visit/Visit-Listing-Template";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
/*import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);*/

export default {
  mixins: [CommonMixin],
  data() {
    return {
      formLoading: false,
      tab: null,
      quotationTab: "line-item",
      dbQuotation: {},
      contractSchedule: [],
      quotationId: 0,
      pageLoaded: false,
      mailDialog: false,
      confirmDialog: false,
      confirmLoading: false,
      scheduleDialog: false,
      confirmType: null,
      actionData: {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      pageLoading: true,
    };
  },
  methods: {
    routeToEnquiry() {
      this.$router.push(
        this.getDefaultRoute("enquiry.detail", {
          params: { id: this.dbQuotation?.enquiry },
        })
      );
    },
    routeToContract() {
      this.$router.push(
        this.getDefaultRoute("contract.detail", {
          params: { id: this.dbQuotation?.contract_relation?.id },
        })
      );
    },
    confirmSubmit() {
      if (this.actionData && !this.actionData?.remark) {
        ErrorEventBus.$emit("update:error", "Remark filed is required.");
        return false;
      }

      switch (this.confirmType) {
        case "mark_as_closed":
          this.updateStatus("mark_as_closed");
          break;
        case "mark_as_cancelled":
          this.updateStatus("mark_as_cancelled");
          break;
        case "mark_as_pending":
          this.updateStatus("mark_as_pending");
          break;
        case "mark_as_rejected":
          this.updateStatus("mark_as_rejected");
          break;
        case "mark_as_accepted":
          this.updateStatus("mark_as_accepted");
          break;
        case "mark_as_approved":
          this.updateStatus("mark_as_approved");
          break;
      }
    },
    uploadFiles() {
      let _this = this;
      return new Promise((resolve, reject) => {
        let formData = new FormData();

        let status = false;

        for (let i = 0; i < _this.actionData.attachments.length; i++) {
          if (_this.actionData.attachments[i].accepted_file) {
            status = true;
            formData.append(
              `files[${i}]`,
              _this.actionData.attachments[i].accepted_file,
              _this.actionData.attachments[i].file_name
            );
          }
        }

        if (!status) {
          resolve([]);
        } else {
          _this.$store
            .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    async updateStatus() {
      if (this.confirmLoading) {
        return false;
      }

      this.confirmLoading = true;

      this.actionData.status = this.confirmType;
      this.actionData.attachments = await this.uploadFiles();

      this.$store
        .dispatch(PATCH, {
          url: "quotation/" + this.quotationId + "/status",
          data: this.actionData,
        })
        .then(() => {
          this.getQuotation();
          this.resetConfirm();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    resetConfirm() {
      this.confirmDialog = false;
      this.confirmType = null;
      this.actionData = {
        remark: null,
        status: null,
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      };
    },
    updateFileName(index) {
      const file = this.actionData.attachments[index].accepted_file;
      if (file) {
        this.actionData.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.actionData.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.addAttachmentRow();
      }
    },
    removeRow(index) {
      ObjectPath.del(this.actionData.attachments, index);
      this.$nextTick(() => {
        if (!this.actionData.attachments.length) {
          this.addAttachmentRow();
        }
      });
    },
    addAttachmentRow() {
      if (this.actionData.attachments.length < 5) {
        this.actionData.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    getFileExtension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    getConfirmTitle() {
      if (this.confirmType == "mark_as_closed") {
        return "Mark as Lost";
      } else if (this.confirmType == "mark_as_cancelled") {
        return "Mark as Cancelled";
      } else if (this.confirmType == "mark_as_pending") {
        return "Mark as Pending";
      } else if (this.confirmType == "mark_as_rejected") {
        return "Mark as Rejected";
      } else if (this.confirmType == "mark_as_accepted") {
        return "Mark as Accepted";
      } else if (this.confirmType == "mark_as_approved") {
        return "Mark as Approved";
      }
    },
    routeToInvoice() {
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: { quotation: this.dbQuotation?.id },
        })
      );
    },
    routeToJob() {
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            quotation: this.dbQuotation?.id,
            "quote-type": this.dbQuotation?.type,
          },
        })
      );
    },
    routeToDuplicate() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { duplicate: this.dbQuotation?.id },
        })
      );
    },
    editQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { edit: this.dbQuotation?.id },
        })
      );
    },
    reviseQuotation() {
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: { revise: this.dbQuotation?.id },
        })
      );
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "quotation/" + this.quotationId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    updateMoreAction(action) {
      this.resetConfirm();
      switch (action) {
        case "edit":
          this.editQuotation();
          break;
        case "revise":
          this.reviseQuotation();
          break;
        case "convert_visit":
          this.routeToJob();
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "mark_as_closed":
          this.confirmDialog = true;
          this.confirmType = "mark_as_closed";
          break;
        case "mark_as_cancelled":
          this.confirmDialog = true;
          this.confirmType = "mark_as_cancelled";
          break;
        case "send_as_email":
          this.mailDialog = true;
          break;
        case "mark_as_pending":
          this.confirmDialog = true;
          this.confirmType = "mark_as_pending";
          break;
        case "mark_as_rejected":
          this.confirmDialog = true;
          this.confirmType = "mark_as_rejected";
          break;
        case "mark_as_accepted":
          this.confirmDialog = true;
          this.confirmType = "mark_as_accepted";
          break;
        case "mark_as_approved":
          this.confirmDialog = true;
          this.confirmType = "mark_as_approved";
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },

    downloadPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=contract&token=${token}&action=download`;
      window.open(url, "_blank");
    },
    printPDF() {
      const token = JwtService.getToken();
      const url = `${this.$apiURL}quotation-v1/${this.quotationId}/pdf?type=contract&token=${token}&action=print`;
      window.open(url, "_blank");
    },
    getQuotation() {
      this.$store
        .dispatch(GET, { url: "quotation-v1/" + this.quotationId })
        .then(({ data }) => {
          this.contractSchedule = data.contract_schedule;
          delete data.contract_schedule;
          this.dbQuotation = data;

          this.$store.dispatch(SET_VIEW_CALCULATION, {
            additional_rows: this.dbQuotation?.additional_rows ?? [],
            ctx_discount_label: this.dbQuotation?.ctx_discount_label ?? null,
            ctx_discount_value: this.dbQuotation?.ctx_discount_value ?? 0,
            ctx_discount: this.dbQuotation?.ctx_discount ?? 0,
            ctx_discount_type: this.dbQuotation?.ctx_discount_type ?? 1,
            sub_total: this.dbQuotation?.sub_total ?? 1,
            discount_type: this.dbQuotation?.discount_type ?? 1,
            discount_value: this.dbQuotation?.discount_value ?? 0,
            discount_amount: this.dbQuotation?.discount_amount ?? 0,
            tax_active: this.dbQuotation?.tax_active ?? false,
            tax_value: this.dbQuotation?.tax_value ?? 0,
            tax_amount: this.dbQuotation?.tax_amount ?? 0,
            adjustment: this.dbQuotation?.adjustment ?? 0,
            total: this.dbQuotation?.total ?? 0,
            show_price: true,
            discount_level: this.dbQuotation?.discount_level ?? "transaction",
          });
          this.$store.dispatch(SET_VIEW_LINE_ITEM, data.line_item);
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Quotation", route: "quotation" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          this.pageLoading = false;
        });
    },
  },
  created() {
    this.quotationId = this.lodash.toSafeInteger(this.$route.params.id);
    this.quotationTab = this.$route.query.tab ?? "overview";
  },
  mounted() {
    this.getQuotation();
  },
  computed: {
    ...mapGetters(["contractRecurringScheduleTypeList"]),
    maintenanceType() {
      return this.contractRecurringScheduleTypeList.find(
        (row) => row.value == this.dbQuotation?.contract_type
      );
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
  components: {
    "line-item-view": LineItemViewV2,
    "internal-history-detail": InternalHistoryDetail,
    "internal-invoice": InternalInvoice,
    "internal-visit": InternalVisit,
    "confirm-dialog": Dialog,
    "send-mail": SendMail,
  },
};
</script>
<style lang="scss" scoped>
.quotation-detail-page {
  border-top: solid 8px #f7941e;
  label {
    padding-left: 5px;
    font-weight: 600;
  }
  p {
    padding-left: 5px;
  }
}
</style>
